import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import FacebookLogo from '@crehana/icons/FacebookLogo';
import GoogleIcon from '@crehana/icons/GoogleIcon';
import { Tooltip } from '@crehana/ui';
import { white } from '@crehana/ui/dist/styles/colors';

import LoadingOverlay from '../../LoadingOverlay';
import MicrosoftButton from '../../MicrosoftButton';

const configuration: Configuration = {
  auth: {
    clientId: '2660be26-2a13-4cec-82c3-337ab10852b0',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/home/',
  },
};

const pca = new PublicClientApplication(configuration);

type SocialButtonsVariantMinimalV4Props = {
  shouldRenderFacebookButton: boolean;
  facebookButtonProps: {
    onClick: () => void;
  };
  gmailButtonProps: {
    id: string;
    ref: React.RefObject<HTMLButtonElement>;
  };
  processingSocialAuth: boolean;
  loadingFacebookAndGoogleScripts: boolean;
  currentForm?: string;
  hideFBAuthButton?: boolean;
  hideFBAuthLink?: boolean;
  showMicrosoftButton?: boolean;
  nextUrl?: string;
  facebookLabel: string;
  googleLabel: string;
};
const SocialButtonsVariantMinimalV4: React.FC<
  React.PropsWithChildren<SocialButtonsVariantMinimalV4Props>
> = ({
  shouldRenderFacebookButton,
  facebookButtonProps,
  gmailButtonProps,
  processingSocialAuth,
  loadingFacebookAndGoogleScripts,
  hideFBAuthButton,
  hideFBAuthLink,
  showMicrosoftButton,
  nextUrl,
  facebookLabel,
  googleLabel,
}) => {
  const renderFBAuth = () => {
    if (hideFBAuthButton && hideFBAuthLink) return null;
    if (hideFBAuthButton && !hideFBAuthLink) {
      return (
        <Tooltip label={facebookLabel} position="bottom" type="regular">
          <a
            className="tw-flex tw-items-center tw-justify-center tw-w-4.8 tw-h-4.8 tw-bg-white tw-rounded-full"
            href={`https://www.crehana.com/login/facebook/?next=${nextUrl}`}
            {...facebookButtonProps}
          >
            <FacebookLogo size={28} color={white} />
          </a>
        </Tooltip>
      );
    }
    return (
      <Tooltip label={facebookLabel} position="bottom" type="regular">
        <button
          className="tw-flex tw-items-center tw-justify-center tw-w-4.8 tw-h-4.8 tw-bg-white tw-rounded-full"
          type="button"
          {...facebookButtonProps}
        >
          <FacebookLogo size={28} />
        </button>
      </Tooltip>
    );
  };

  return (
    <div className="w-full flex">
      <div className="flex items-center justify-center m-auto max-w-280">
        {shouldRenderFacebookButton && renderFBAuth()}
        <Tooltip label={googleLabel} position="bottom" type="regular">
          <button
            className="tw-flex tw-items-center tw-justify-center tw-w-4.8 tw-h-4.8 tw-mr-0.8 tw-bg-white tw-rounded-full"
            type="button"
            style={{
              display: window.navigator.userAgent
                .toLowerCase()
                .includes('instagram')
                ? 'none !important'
                : 'flex',
            }}
            {...gmailButtonProps}
          >
            <GoogleIcon size={28} color={white} />
          </button>
        </Tooltip>
      </div>
      {showMicrosoftButton && (
        <MsalProvider instance={pca}>
          <div className="pl-16">
            <MicrosoftButton v4 />
          </div>
        </MsalProvider>
      )}
      {(processingSocialAuth || loadingFacebookAndGoogleScripts) && (
        <LoadingOverlay />
      )}
    </div>
  );
};

export default SocialButtonsVariantMinimalV4;
