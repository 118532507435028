import { useMutation } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { useRouter } from 'next/router';

import { useAmplitude } from '@crehana/analytics';
import { cn } from '@crehana/compass.ui/utils';
import { useTranslation } from '@crehana/i18n';
import Microsoft from '@crehana/icons/logos/Microsoft';
import { Tooltip } from '@crehana/ui';

import SOCIAL_LOGIN_MUTATION_V4 from '../graphql/SocialLoginB2B.authentication.graphql';
import {
  SocialLoginB2B,
  SocialLoginB2BVariables,
} from '../graphql/types/SocialLoginB2B';

type MicrosoftButtonProps = {
  v4?: boolean;
};

const MicrosoftButton = ({ v4 }: MicrosoftButtonProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { logEvent } = useAmplitude();

  const { slug } = router.query as { slug: string };

  const [socialLoginV4] = useMutation<SocialLoginB2B, SocialLoginB2BVariables>(
    SOCIAL_LOGIN_MUTATION_V4,
    { context: { clientName: 'authentication' } },
  );

  const loginV4 = async (variables: SocialLoginB2BVariables) =>
    socialLoginV4({
      variables: {
        token: variables.token,
        backend: variables.backend,
        externalOrganization: variables.externalOrganization,
      },
    });

  const loginWithMS = (id: string) => {
    loginV4({
      token: id,
      backend: 'azuread-oauth2',
      externalOrganization: slug,
    }).then(response => {
      if (response.data?.socialLogin?.response?.type === 'success') {
        logEvent({
          event: 'Successful SignIn',
          data: {
            source: 'Microsoft',
          },
        });
        window.location.href = '/home/';
      }
    });
  };

  const login = () => {
    instance
      .loginPopup({
        scopes: ['openid', 'profile', 'email'],
      })
      .then(response => {
        if (response) loginWithMS(response.idToken);
      });
  };

  return (
    <Tooltip
      label={t('LOGIN_WITH_MICROSOFT_SIGN_IN')}
      position="bottom"
      type="regular"
    >
      <button
        type="button"
        className={cn(
          !v4 &&
            'flex items-center justify-center w-56 h-48 bg-white rounded-5 shadow-4-dp',
          v4 &&
            'tw-flex tw-items-center tw-justify-center tw-w-4.8 tw-h-4.8 tw-bg-white',
        )}
        onClick={() => login()}
      >
        <Microsoft size={v4 ? 24 : 28} />
      </button>
    </Tooltip>
  );
};

export default MicrosoftButton;
