import { FC, useEffect, useState } from 'react';

import { MeObject } from '@crehana/auth';
import { useTranslation } from '@crehana/i18n';
import FacebookLogo from '@crehana/icons/Facebook';
import GoogleIcon from '@crehana/icons/GoogleIcon';

import { AuthFormSocialloginMutation } from '../../graphql/types/AuthFormSocialloginMutation';
import { getNextUrl } from '../../utils';
import LoadingOverlay from '../LoadingOverlay';
import { useSocialAuth } from './react-hooks';
import SocialButtonsVariantMinimal from './variants/Minimal';
import SocialButtonsVariantMinimalV2 from './variants/MinimalV2';
import SocialButtonsVariantMinimalV3 from './variants/MinimalV3';
import SocialButtonsVariantMinimalV4 from './variants/MinimalV4';

const baseButtonClasses =
  'h-56 w-full flex justify-center items-center cursor-pointer text-center bg-white outline-none rounded-5 mb-16';

export interface SocialButtonsProps {
  shouldRenderFacebookButton?: boolean;
  referFriendId?: string;
  nextUrl?: string;
  customHandler?(params: {
    redirect?: string | null;
    user?: MeObject;
    isNewUser?: boolean | null;
    graphqlResponse?: AuthFormSocialloginMutation['socialLogin'];
  }): void;
  variant?: 'minimal' | 'minimal-2' | 'minimal-3' | 'minimal-4';
  currentForm?: string;
  hideFacebookButton?: boolean;
  hideFacebookLink?: boolean;
  showMicrosoftButton?: boolean;
  friendInvitationId?: string;
  authType?: 'signUp' | 'signIn';
}

const SocialButtons: FC<React.PropsWithChildren<SocialButtonsProps>> = ({
  shouldRenderFacebookButton = true,
  referFriendId,
  nextUrl: _nextUrl,
  customHandler,
  variant,
  currentForm,
  hideFacebookButton,
  hideFacebookLink,
  showMicrosoftButton,
  friendInvitationId,
  authType = 'signIn',
}) => {
  const { t } = useTranslation();
  const [showButtonCtaFacebook, setShowButtonCtaFacebook] = useState<
    'none' | 'flex'
  >('flex');

  const {
    gmailButtonProps,
    facebookButtonProps,
    setupAndInitSocialAuthScripts,
    authState: {
      processingSocialAuth,
      loadingFacebookAndGoogleScripts,
      errorToLoadFacebookAndGoogleScripts,
    },
  } = useSocialAuth({
    nextUrl: friendInvitationId ? '/premium/' : _nextUrl,
    referFriendId,
    friendInvitationId,
    onSuccess: customHandler,
    hideFacebookLink,
    getNextUrl: friendInvitationId ? undefined : getNextUrl,
  });

  useEffect(() => {
    setupAndInitSocialAuthScripts();

    const isInstagramWebView = window.navigator.userAgent
      .toLowerCase()
      .includes('instagram');

    setShowButtonCtaFacebook(isInstagramWebView ? 'none' : 'flex');
  }, []);

  const facebookLabel =
    authType === 'signUp'
      ? t('LOGIN_WITH_FACEBOOK_SING_UP', 'Regístrate con Facebook')
      : t('LOGIN_WITH_FACEBOOK_SIGN_IN', 'Ingresar con Facebook');
  const googleLabel =
    authType === 'signUp'
      ? t('LOGIN_WITH_GOOGLE_SING_UP', 'Regístrate con Google')
      : t('LOGIN_WITH_GOOGLE_SIGN_IN', 'Ingresar con Google');

  const renderFacebookButton = () => {
    if (hideFacebookButton && hideFacebookLink) return null;

    if (hideFacebookButton && !hideFacebookLink) {
      const urlToNext = _nextUrl ? `?next=${_nextUrl}` : '';
      const next = friendInvitationId ? '?next=/premium/' : urlToNext;

      return (
        <a
          href={`https://www.crehana.com/login/facebook/${next}`}
          className="items-center justify-center w-full h-56 px-16 sm:px-32 mb-8 rounded-5"
          style={{
            backgroundColor: '#1877F2',
            display: showButtonCtaFacebook,
            pointerEvents:
              processingSocialAuth ||
              loadingFacebookAndGoogleScripts ||
              errorToLoadFacebookAndGoogleScripts
                ? 'none'
                : 'all',
          }}
        >
          <FacebookLogo color="white" size={26} />{' '}
          <span className="block ml-24 text-white font-link">
            {facebookLabel}
          </span>
        </a>
      );
    }

    return (
      <button
        type="button"
        className="items-center justify-center w-full h-56 px-16 sm:px-32 mb-8 rounded-5"
        style={{
          backgroundColor: '#1877F2',
          display: showButtonCtaFacebook,
        }}
        {...facebookButtonProps}
        disabled={
          processingSocialAuth ||
          loadingFacebookAndGoogleScripts ||
          errorToLoadFacebookAndGoogleScripts
        }
      >
        <FacebookLogo color="white" size={26} />{' '}
        <span className="block ml-24 text-white font-link">
          {facebookLabel}
        </span>
      </button>
    );
  };

  if (variant === 'minimal') {
    return (
      <SocialButtonsVariantMinimal
        shouldRenderFacebookButton={shouldRenderFacebookButton}
        facebookButtonProps={facebookButtonProps}
        gmailButtonProps={gmailButtonProps}
        processingSocialAuth={processingSocialAuth}
        currentForm={currentForm}
        loadingFacebookAndGoogleScripts={loadingFacebookAndGoogleScripts}
        hideFBAuthButton={hideFacebookButton}
        hideFBAuthLink={hideFacebookLink}
        nextUrl={_nextUrl}
      />
    );
  }

  if (variant === 'minimal-2') {
    return (
      <SocialButtonsVariantMinimalV2
        shouldRenderFacebookButton={shouldRenderFacebookButton}
        facebookButtonProps={facebookButtonProps}
        gmailButtonProps={gmailButtonProps}
        processingSocialAuth={processingSocialAuth}
        currentForm={currentForm}
        loadingFacebookAndGoogleScripts={loadingFacebookAndGoogleScripts}
        hideFBAuthButton={hideFacebookButton}
        hideFBAuthLink={hideFacebookLink}
        nextUrl={_nextUrl}
        facebookLabel={facebookLabel}
        googleLabel={googleLabel}
      />
    );
  }

  if (variant === 'minimal-3') {
    return (
      <SocialButtonsVariantMinimalV3
        shouldRenderFacebookButton={shouldRenderFacebookButton}
        facebookButtonProps={facebookButtonProps}
        gmailButtonProps={gmailButtonProps}
        processingSocialAuth={processingSocialAuth}
        currentForm={currentForm}
        loadingFacebookAndGoogleScripts={loadingFacebookAndGoogleScripts}
        hideFBAuthButton={hideFacebookButton}
        hideFBAuthLink={hideFacebookLink}
        nextUrl={_nextUrl}
        facebookLabel={facebookLabel}
        googleLabel={googleLabel}
        showMicrosoftButton={showMicrosoftButton}
      />
    );
  }

  if (variant === 'minimal-4') {
    return (
      <SocialButtonsVariantMinimalV4
        shouldRenderFacebookButton={shouldRenderFacebookButton}
        facebookButtonProps={facebookButtonProps}
        gmailButtonProps={gmailButtonProps}
        processingSocialAuth={processingSocialAuth}
        currentForm={currentForm}
        loadingFacebookAndGoogleScripts={loadingFacebookAndGoogleScripts}
        hideFBAuthButton={hideFacebookButton}
        hideFBAuthLink={hideFacebookLink}
        nextUrl={_nextUrl}
        facebookLabel={facebookLabel}
        googleLabel={googleLabel}
        showMicrosoftButton={showMicrosoftButton}
      />
    );
  }

  return (
    <div className="relative w-full">
      {shouldRenderFacebookButton && renderFacebookButton()}
      <button
        type="button"
        className={`${baseButtonClasses}`}
        style={{
          border: '1px solid rgba(0, 153, 255, 0.3)',
          display: window.navigator.userAgent
            .toLowerCase()
            .includes('instagram')
            ? 'none'
            : 'flex',
        }}
        {...gmailButtonProps}
      >
        <div className="mr-4">
          <GoogleIcon size={22} />
        </div>
        <span className="tw-font-semibold tw-font-pop">{googleLabel}</span>
      </button>
      {(processingSocialAuth || loadingFacebookAndGoogleScripts) && (
        <LoadingOverlay />
      )}
    </div>
  );
};

export default SocialButtons;
